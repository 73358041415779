import { Nullable } from '../typescript/nullable';
import { UserInterface } from '../user/user.interface';

export enum UserReferralStep {
	REGISTERED = 'REGISTERED',
	DREAMER_RANK = 'DREAMER_RANK',
}

export interface UserReferralInterface {
	id: string;
	referredAt: Date;
	/*
	 * If user A refers user B. referredUser is the user who has been referred.
	 * In this case, user B
	 */
	referredUser: Nullable<Pick<UserInterface, 'fullName' | 'email'> & { thumbnailUrl: string }>;
	completedSteps: UserReferralStep[];
	isCompleted: boolean;
	isReadyToClaim: boolean;
	isClaimed: boolean;
	completedAt: Nullable<Date>;
	claimedAt: Nullable<Date>;
	rewardCreditsAmount: number;
}
