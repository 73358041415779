import { ReachRewardEnum } from './rewards.enum';

export interface ReachRewardsInterface {
	rewards: ReachRewardInterface[];
}
export interface ReachRewardInterface {
	reachReward: ReachRewardEnum;
	credits: number;
	boost: number;
}

export type UpdateReachRewards = ReachRewardsInterface;
