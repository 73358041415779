export enum FormKeyEnum {
	LEGACY_USERS_BEFORE_2023 = 'LEGACY_USERS_BEFORE_2023',
	NEW_USER = 'NEW_USER',
	RANK_UP_TO_ADVENTURER = 'RANK_UP_TO_ADVENTURER',
	RANK_UP_TO_EXPERT = 'RANK_UP_TO_EXPERT',
	RANK_UP_TO_EXPLORER = 'RANK_UP_TO_EXPLORER',
	RANK_UP_TO_MASTER = 'RANK_UP_TO_MASTER',
	RANK_UP_TO_PIONEER = 'RANK_UP_TO_PIONEER',
	RANK_UP_TO_VISIONARY = 'RANK_UP_TO_VISIONARY',
}
