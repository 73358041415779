import { UserWithoutSensitive } from './user.interface';
import { RankKeyEnum } from '../ranks';

export interface UserInPing extends UserWithoutSensitive {
	numExperiencePoints: number;
	level: number;
	rank: RankKeyEnum;
	numCredits: number;
	leader?: UserWithoutSensitive;
}
