export enum HeaderColor {
	BY_RANK = 'byRank',
	BLUE = 'blue',
	GREENISH_BLUE = 'greenishBlue',
	GREEN = 'green',
	YELLOWISH_GREEN = 'yellowishGreen',
	YELLOW = 'yellow',
	ORANGE = 'orange',
	REDISH_ORANGE = 'redishOrange',
	RED = 'red',
}
