export enum WorkspaceTemplate {
	BLANK_TEMPLATE = 'BLANK_TEMPLATE',
	BUCKET_LIST = 'BUCKET_LIST',
	BUILDING_PROJECT = 'BUILDING_PROJECT',
	COMPANY_WIKI = 'COMPANY_WIKI',
	CRM = 'CRM',
	ENTERTAINMENT = 'ENTERTAINMENT',
	FAMILY = 'FAMILY',
	GAMING = 'GAMING',
	HOME = 'HOME',
	HOUSE_HUNTING = 'HOUSE_HUNTING',
	HR = 'HR',
	INVESTMENTS = 'INVESTMENTS',
	JOB_APPLICATIONS = 'JOB_APPLICATIONS',
	JOURNALISM = 'JOURNALISM',
	LEGAL = 'LEGAL',
	MARKET_STUDY = 'MARKET_STUDY',
	MEDICAL = 'MEDICAL',
	PETS = 'PETS',
	PROJECT = 'PROJECT',
	RECIPES = 'RECIPES',
	RESTAURANTS = 'RESTAURANTS',
	SPORTS_AND_FITNESS = 'SPORTS_AND_FITNESS',
	STUDIES = 'STUDIES',
	THESIS = 'THESIS',
	TRIPS_AND_TRAVEL = 'TRIPS_AND_TRAVEL',
	WORK = 'WORK',
}
