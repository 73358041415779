import { EmailElementInterface, EmailElementType } from './email-content.types';

export interface EmailTemplateInterface<T extends EmailElementType = EmailElementType> {
	id: string;
	name: string;
	author: string | null;
	lastModified: Date;
	emailContent: EmailElementInterface<T>;
	category?: EmailTemplateCategory;
	status: EmailTemplateStatus;
	lastSent: Date | null;
}

export enum EmailTemplateCategory {
	ONBOARDING = 'ONBOARDING',
	ACTIVATION = 'ACTIVATION',
	BIRTHDAY = 'BIRTHDAY',
	WE_MISS_YOU = 'WE_MISS_YOU',
	ACCOUNT = 'ACCOUNT',
	SURVEY = 'SURVEY',
	REFERRAL = 'REFERRAL',
	UPDATES = 'UPDATES',
	MILESTONES = 'MILESTONES',
}
export enum EmailTemplateStatus {
	TEMPLATE = 'TEMPLATE',
	TRASH = 'TRASH',
	DRAFT = 'DRAFT',
	SENT = 'SENT',
}

// REQUESTS

export type EmailTemplateCreateBody = Omit<
	EmailTemplateInterface,
	'id' | 'author' | 'lastModified' | 'lastSent'
>;

export type EmailTemplateUpdateBody = Partial<
	Omit<EmailTemplateInterface, 'id' | 'author' | 'lastModified' | 'lastSent'>
>;

export type EmailTemplateQuery = Partial<
	Pick<EmailTemplateInterface, 'name' | 'status' | 'category'>
>;
