import { FormKeyEnum } from './form-key.enum';
import { StringWithKnownValues } from '../typescript';

export interface FormInterface {
	id: StringWithKnownValues<FormKeyEnum>;
	name: string;
	description: string;
	url: string;
	isReadyToFill: boolean;
	isCompleted: boolean;
	rewardCreditsAmount: number;
}
