import { AchievementKeyEnum } from '../achievements';
import { BadgeKeyEnum } from '../badges';
import { RankKeyEnum } from '../ranks';
import { Nullable } from '../typescript';
import { CreditTypeEnum } from './credit-type.enum';

// If you want to distinguish between entities that extend credit
// Then write a user-defined type guard that returns a type predicate using the type field
export interface CreditInterface {
	id: string;
	userId: string;
	amount: number;
	type: CreditTypeEnum;
	createdAt: Date;
	validFrom: Date | null;
	validUntil: Date | null;
	// Fields that depend on the type discriminator
	note?: string | null;
	achievementId?: Nullable<AchievementKeyEnum>;
	badgeId?: Nullable<BadgeKeyEnum>;
	rankId?: Nullable<RankKeyEnum>;
	userReferralId?: Nullable<string>;
}
