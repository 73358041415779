import { BadgeKeyEnum } from './badge-key.enum';
import { CompletableInterface } from '../utils';

export interface BadgeInterface extends CompletableInterface {
	id: BadgeKeyEnum;
	position: number;
	level: number;
	maxLevel: number;
	lastAchievementClaimedAt: Date | null;
	hasAchievementsLeftToClaim: boolean;
}
