import { ClientMetadataOs } from '../client-metadata';
import { NodeType } from '../nodes';
import { FirstAction } from './first-action.enum';
import { GeneralUse } from './general-use.enum';
import { UserRegisteredSource } from './user-registered-source.types';
import { WorkspaceTemplate } from './workspace-template.enum';

export enum UserSummaryInfo {
	ALL = '',
	UNWANTED = 'unwanted',
	PENDING = 'pending',
	ALPHA = 'alpha',
	EMAIL_SUBSCRIPTION = 'email_subscription',
	DELETED = 'deleted',
}

export enum UserTimeInterval {
	ALL_TIME = '',
	WEEKLY = 'week',
	DAILY = 'day',
	MONTHLY = 'month',
	QUARTERLY = 'quarter',
	YEARLY = 'year',
}

export enum UserTimeNumberInterval {
	ONE = '1',
	FIVE = '5',
	TEN = '10',
	FIFTEEN = '15',
	TWENTY = '20',
	THIRTY = '30',
	SIXTY = '60',
}

export enum UserSignupMethod {
	GOOGLE = 'google',
	REACH = 'reach',
	BOTH = 'both',
	NULL = 'null',
}

export interface OSStats {
	count: number;
	registeredOS: ClientMetadataOs;
}
export interface CountryStats {
	count: number;
	registerLocation: string;
}
export interface PlatformStats {
	count: number;
	registeredSource: UserRegisteredSource;
}
export interface UserSummaryInterface {
	count: number;
	average: number;
}

export interface StatisticAggregationItem<K = string> {
	name: K;
	value: number;
}

export type User2DSummary = { [key: string]: StatisticAggregationItem[] };
export type UserSummaryData = { [type in UserSummaryInfo]: UserSummaryInterface };

export type UserSummaryGraphData = {
	[type in UserSummaryInfo]: { name: type; series: StatisticAggregationItem[] };
};

export type UserSignupData = { name: string; series: StatisticAggregationItem[] }[];

export interface StatisticSerie<T> {
	date: string;
	series: StatisticAggregationItem<T>[];
}

export type NodeCount = {
	[nodeType in NodeType]: number;
};

export type TemplateCount = {
	[template in WorkspaceTemplate]: number;
};
export type FirstActionCount = {
	[template in FirstAction]: number;
};

export type GeneralUseCount = {
	[template in GeneralUse]: number;
};

export type UserTypeDailyCounts = {
	[type in UserSummaryInfo]: {
		[day: string]: number;
	};
};
