export enum ReachRewardEnum {
	REFERRAL = 'REFERRAL',
	FOLLOW_INSTAGRAM = 'FOLLOW_INSTAGRAM',
	FOLLOW_LINKEDIN = 'FOLLOW_LINKEDIN',
	FOLLOW_TWITTER = 'FOLLOW_TWITTER',
	WRITE_BLOG = 'WRITE_BLOG',
	TWEET_ABOUT_REACH = 'TWEET_ABOUT_REACH',
	INSTAGRAM_POST_STORY = 'INSTAGRAM_POST_STORY',
	ANSWER_TYPEFORM = 'ANSWER_TYPEFORM',
	DOWNLOAD_APP = 'DOWNLOAD_APP',
	DOWNLOAD_PLUGIN = 'DOWNLOAD_PLUGIN',
	ANSWER_ENIGMA = 'ANSWER_ENIGMA',
	FRIENDS_OF_FRIENDS = 'FRIENDS_OF_FRIENDS',
}
