export enum AchievementKeyEnum {
	DOWNLOAD_THE_APP = 'DOWNLOAD_THE_APP',
	DOWNLOAD_THE_PLUGIN = 'DOWNLOAD_THE_PLUGIN',
	SAVE_SOMETHING_1 = 'SAVE_SOMETHING_1',
	SAVE_SOMETHING_2 = 'SAVE_SOMETHING_2',
	SAVE_SOMETHING_3 = 'SAVE_SOMETHING_3',
	SAVE_SOMETHING_4 = 'SAVE_SOMETHING_4',
	SAVE_SOMETHING_5 = 'SAVE_SOMETHING_5',
	SAVE_SOMETHING_6 = 'SAVE_SOMETHING_6',
	SAVE_SOMETHING_7 = 'SAVE_SOMETHING_7',
	CREATE_NODES_1 = 'CREATE_NODES_1',
	CREATE_NODES_2 = 'CREATE_NODES_2',
	CREATE_NODES_3 = 'CREATE_NODES_3',
	CREATE_NODES_4 = 'CREATE_NODES_4',
	CREATE_NODES_5 = 'CREATE_NODES_5',
	CREATE_NODES_6 = 'CREATE_NODES_6',
	CREATE_NODES_7 = 'CREATE_NODES_7',
	CREATE_NODES_8 = 'CREATE_NODES_8',
	USE_NOTE_TEMPLATE = 'USE_NOTE_TEMPLATE',
	USE_WORKSPACE_TEMPLATE = 'USE_WORKSPACE_TEMPLATE',
	LOGIN_CONSECUTIVE_DAYS_1 = 'LOGIN_CONSECUTIVE_DAYS_1',
	LOGIN_CONSECUTIVE_DAYS_2 = 'LOGIN_CONSECUTIVE_DAYS_2',
	LOGIN_CONSECUTIVE_DAYS_3 = 'LOGIN_CONSECUTIVE_DAYS_3',
	LOGIN_CONSECUTIVE_DAYS_4 = 'LOGIN_CONSECUTIVE_DAYS_4',
	LOGIN_CONSECUTIVE_DAYS_5 = 'LOGIN_CONSECUTIVE_DAYS_5',
	LOGIN_CONSECUTIVE_DAYS_6 = 'LOGIN_CONSECUTIVE_DAYS_6',
	LOGIN_CONSECUTIVE_DAYS_7 = 'LOGIN_CONSECUTIVE_DAYS_7',
	LOGIN_CONSECUTIVE_DAYS_8 = 'LOGIN_CONSECUTIVE_DAYS_8',
	IMPORT_PHONE_CONTACTS = 'IMPORT_PHONE_CONTACTS',
	IMPORT_BOOKMARKS = 'IMPORT_BOOKMARKS',
	INTEGRATE_TOOL_1 = 'INTEGRATE_TOOL_1',
	INTEGRATE_TOOL_2 = 'INTEGRATE_TOOL_2',
	INTEGRATE_TOOL_3 = 'INTEGRATE_TOOL_3',
	INTEGRATE_TOOL_4 = 'INTEGRATE_TOOL_4',
	INTEGRATE_TOOL_5 = 'INTEGRATE_TOOL_5',
	WATCH_TUTORIAL_1 = 'WATCH_TUTORIAL_1',
	WATCH_TUTORIAL_2 = 'WATCH_TUTORIAL_2',
	WATCH_TUTORIAL_3 = 'WATCH_TUTORIAL_3',
	WATCH_TUTORIAL_4 = 'WATCH_TUTORIAL_4',
	WATCH_TUTORIAL_5 = 'WATCH_TUTORIAL_5',
	WATCH_TUTORIAL_6 = 'WATCH_TUTORIAL_6',
	SAVE_WEBSITE_WITH_PLUGIN_1 = 'SAVE_WEBSITE_WITH_PLUGIN_1',
	SAVE_WEBSITE_WITH_PLUGIN_2 = 'SAVE_WEBSITE_WITH_PLUGIN_2',
	SAVE_WEBSITE_WITH_PLUGIN_3 = 'SAVE_WEBSITE_WITH_PLUGIN_3',
	SAVE_WEBSITE_WITH_PLUGIN_4 = 'SAVE_WEBSITE_WITH_PLUGIN_4',
	SAVE_WEBSITE_WITH_PLUGIN_5 = 'SAVE_WEBSITE_WITH_PLUGIN_5',
	SAVE_WEBSITE_WITH_PLUGIN_6 = 'SAVE_WEBSITE_WITH_PLUGIN_6',
	SAVE_WEBSITE_WITH_PLUGIN_7 = 'SAVE_WEBSITE_WITH_PLUGIN_7',
	CREATE_RELATIONSHIP_1 = 'CREATE_RELATIONSHIP_1',
	CREATE_RELATIONSHIP_2 = 'CREATE_RELATIONSHIP_2',
	CREATE_RELATIONSHIP_3 = 'CREATE_RELATIONSHIP_3',
	CREATE_RELATIONSHIP_4 = 'CREATE_RELATIONSHIP_4',
	CREATE_RELATIONSHIP_5 = 'CREATE_RELATIONSHIP_5',
	CREATE_RELATIONSHIP_6 = 'CREATE_RELATIONSHIP_6',
	CREATE_RELATIONSHIP_7 = 'CREATE_RELATIONSHIP_7',
	CREATE_RELATIONSHIP_8 = 'CREATE_RELATIONSHIP_8',
	ADD_PROPERTY_1 = 'ADD_PROPERTY_1',
	ADD_PROPERTY_2 = 'ADD_PROPERTY_2',
	ADD_PROPERTY_3 = 'ADD_PROPERTY_3',
	ADD_PROPERTY_4 = 'ADD_PROPERTY_4',
	ADD_PROPERTY_5 = 'ADD_PROPERTY_5',
	ADD_PROPERTY_6 = 'ADD_PROPERTY_6',
	ADD_PROPERTY_7 = 'ADD_PROPERTY_7',
	ADD_PROPERTY_8 = 'ADD_PROPERTY_8',
	FILL_PROFILE_AVATAR = 'FILL_PROFILE_AVATAR',
	FILL_PROFILE_BIRTHDAY = 'FILL_PROFILE_BIRTHDAY',
	FILL_PROFILE_GENDER = 'FILL_PROFILE_GENDER',
	FILL_PROFILE_NATIONALITY = 'FILL_PROFILE_NATIONALITY',
	FILL_PROFILE_INTERESTS = 'FILL_PROFILE_INTERESTS',
	FILL_PROFILE_PROFESSION = 'FILL_PROFILE_PROFESSION',
	SEND_DISCOURSE_FEEDBACK_1 = 'SEND_DISCOURSE_FEEDBACK_1',
	SEND_DISCOURSE_FEEDBACK_2 = 'SEND_DISCOURSE_FEEDBACK_2',
	SEND_DISCOURSE_FEEDBACK_3 = 'SEND_DISCOURSE_FEEDBACK_3',
	SEND_DISCOURSE_FEEDBACK_4 = 'SEND_DISCOURSE_FEEDBACK_4',
	SEND_DISCOURSE_FEEDBACK_5 = 'SEND_DISCOURSE_FEEDBACK_5',
	SAVE_WITH_SHARE_1 = 'SAVE_WITH_SHARE_1',
	SAVE_WITH_SHARE_2 = 'SAVE_WITH_SHARE_2',
	SAVE_WITH_SHARE_3 = 'SAVE_WITH_SHARE_3',
	SAVE_WITH_SHARE_4 = 'SAVE_WITH_SHARE_4',
	SAVE_WITH_SHARE_5 = 'SAVE_WITH_SHARE_5',
	SAVE_WITH_SHARE_6 = 'SAVE_WITH_SHARE_6',
	INVITE_FRIEND_1 = 'INVITE_FRIEND_1',
	INVITE_FRIEND_2 = 'INVITE_FRIEND_2',
	INVITE_FRIEND_3 = 'INVITE_FRIEND_3',
	INVITE_FRIEND_4 = 'INVITE_FRIEND_4',
	INVITE_FRIEND_5 = 'INVITE_FRIEND_5',
	INVITE_FRIEND_6 = 'INVITE_FRIEND_6',
	ANSWER_DAILY_ENIGMA_1 = 'ANSWER_DAILY_ENIGMA_1',
	ANSWER_DAILY_ENIGMA_2 = 'ANSWER_DAILY_ENIGMA_2',
	ANSWER_DAILY_ENIGMA_3 = 'ANSWER_DAILY_ENIGMA_3',
	ANSWER_DAILY_ENIGMA_4 = 'ANSWER_DAILY_ENIGMA_4',
	ANSWER_DAILY_ENIGMA_5 = 'ANSWER_DAILY_ENIGMA_5',
	ANSWER_DAILY_ENIGMA_6 = 'ANSWER_DAILY_ENIGMA_6',
	ANSWER_DAILY_ENIGMA_7 = 'ANSWER_DAILY_ENIGMA_7',
	ANSWER_DAILY_ENIGMA_8 = 'ANSWER_DAILY_ENIGMA_8',
	SHARE_NODES_OR_WORKSPACES_1 = 'SHARE_NODES_OR_WORKSPACES_1',
	SHARE_NODES_OR_WORKSPACES_2 = 'SHARE_NODES_OR_WORKSPACES_2',
	SHARE_NODES_OR_WORKSPACES_3 = 'SHARE_NODES_OR_WORKSPACES_3',
	SHARE_NODES_OR_WORKSPACES_4 = 'SHARE_NODES_OR_WORKSPACES_4',
	SHARE_NODES_OR_WORKSPACES_5 = 'SHARE_NODES_OR_WORKSPACES_5',
}
