import { BadgeKeyEnum } from '../badges';
import { ClaimableInterface, CompletableInterface } from '../utils';
import { AchievementKeyEnum } from './achievement-key.enum';

export interface AchievementInterface extends ClaimableInterface, CompletableInterface {
	id: AchievementKeyEnum;
	badgeId: BadgeKeyEnum;
	/**
	 * The original order of the achievement, in terms of frontend display
	 */
	position: number;
	/**
	 * How many actions of the achievement have been completed so far
	 */
	numActionsCompleted: number;
	/**
	 * How many actions the achievement requires in order to be completed
	 */
	numActionsToUnlock: number;
}
