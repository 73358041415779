import { XOR } from 'ts-essentials';
import { AlbumInterface } from '../albums';
import { CustomPropertyValue } from '../custom-property';
import { Edge } from '../edges';
import { FileInterface } from '../files';
import { NodeSubtypesSettingsNodeMinimalInfo } from '../node-subtypes-settings';
import { NoteInterface } from '../notes';
import { SearchNodeResponse } from '../search';
import { TagInterface } from '../tags';
import { WebsiteInterface } from '../websites';
import { WorkspaceInterface } from '../workspaces';
import { NodeType } from './node-type.enum';

export interface NodeIdInterface {
	nodeId: string | null;
}

export interface MultipleNodeIdInterface {
	nodeIds: string[];
}

export interface NodeInterface {
	id: string;
	name: string;
	nodeType: NodeType;
	emoji?: string;
	customProperties: CustomPropertyValue[];
	extractedNodeCandidates: NodeSubtypesSettingsNodeMinimalInfo[];
	createdAt: Date;
	updatedAt: Date;
	imageUrl?: string;
	thumbnailUrl?: string;
	mapThumbnailUrl?: string;
}

export interface RelatedNodesInterface {
	id: number;
	name: string;
	nodeType: NodeType;
	label: string;
	userForce: number;
}

export type AnyNode = XOR<
	TagInterface,
	XOR<
		FileInterface,
		XOR<WebsiteInterface, XOR<WorkspaceInterface, XOR<NoteInterface, AlbumInterface>>>
	>
>;

export type GetNodeResponse<T = AnyNode> = Omit<T, 'comments'> & {
	relatedNodesNumber: number;
	workspaceIds: string[];
};

export enum RelatedFromEnum {
	NORMAL = 'NORMAL',
	PROPERTY = 'PROPERTY',
	INVERSE_PROPERTY = 'INVERSE_PROPERTY',
}

export interface GetNodeEdgesDataItem {
	edge: Edge;
	node: SearchNodeResponse;
}

export interface GetNodeEdgesAllItem {
	nodeId: string;
	edgeId: number;
	relatedFrom: RelatedFromEnum;
}

export type GetNodeEdgesResponse = {
	all: GetNodeEdgesAllItem[];
	data: GetNodeEdgesDataItem[];
};
