export interface UserActionLimitsInterface {
	maxSentVerifyReached: boolean;
	maxSentPasswordReached: boolean;
	maxSentDeleteReached: boolean;
	maxDailyEnigmasReached: boolean;
	maxSentUserReferralReached: boolean;
	maxGooglePlacesDetailsRequestReached: boolean;
	maxGooglePlacesSearchRequestReached: boolean;
}

export interface UserActionLimitsResponse {
	limits: UserActionLimitsInterface;
}

export interface UserActionLimitsUpdateRequestInterface {
	countGooglePlacesDetailsRequest?: number;
	countGooglePlacesSearchRequest?: number;
}
