import { Nullable } from '../typescript';
import { UserInterface } from './user.interface';

export interface UserBirthdayPresentInterface {
	id: string;
	user: Nullable<UserInterface>;
	isCompleted: boolean;
	isClaimed: boolean;
	completedAt: Nullable<Date>;
	claimedAt: Nullable<Date>;
	creditsAmount: number;
}

export interface GetUserBirthdayPresentResponse {
	present: Nullable<UserBirthdayPresentInterface>;
}
