export enum BadgeKeyEnum {
	COLLECTOR = 'COLLECTOR',
	CREATOR = 'CREATOR',
	CUSTOMISER = 'CUSTOMISER',
	FANATIC = 'FANATIC',
	GATHERER = 'GATHERER',
	LEARNER = 'LEARNER',
	LEGEND = 'LEGEND',
	MULTITASKER = 'MULTITASKER',
	NETWORKER = 'NETWORKER',
	PERFECTIONIST = 'PERFECTIONIST',
	PHOTOGENIC = 'PHOTOGENIC',
	RACER = 'RACER',
	UNSTOPPABLE = 'UNSTOPPABLE',
	WIZARD = 'WIZARD',
	INFLUENCER = 'INFLUENCER',
	GENIUS = 'GENIUS',
}
